import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Banner from "../../components/Common/Banner"
import Menu from "../../components/IELTS_Academic/Menu"
import RecomendedCourses from "../../components/IELTS_Academic/RecomendedCourses"
import WritingModule from "../../components/IELTS_Academic/WritingModule"

const testId = '5146ad1dc456bf65044d79a6446dd9ac'
const courseId = '695431377ee45b963cebc7fc57eb7669'

const Writing = () => (
  <Layout>
    <SEO title="IELTS Academic - Writing" />
    <main>
        <Banner />
        <Menu />
        <WritingModule testId={testId} courseId={courseId} />
        <RecomendedCourses testId={testId} courseId={courseId} />
    </main>
  </Layout>
)

export default Writing
