import React from "react"
import LaunchTestButton from "../LaunchTestButton"

const WritingModule = (props) => (
    <section className="lrws-section-1">
        <div className="container">
        <div className="listening-wrapper mt-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="listening-image-wrapper">
                    <img src={require('../../images/writing-image.png')} alt="" />
                  </div>
                </div>
                <div className="col-md-8" >
                  <div className="listening-content-wrapper">
                    <h3 className="content-title">
                      Writing
                    </h3>
                    <ul>
                      <li>
                        <span>
                          <img src={require('../../images/duration.png')} alt="" />
                          <strong>Duration : </strong> 60 min (20 min & 40 min)
                        </span>
                      </li>
                    </ul>
                    <div className="listening-sections-wrapper">
                      <p className="content-sub-title pb-0">Sections</p>
                      <p className="note">The listening part has four sections. </p>
                      <div className="content-sections">
                        <div>Task 1</div>
                        <p>Describe given visual information (graph/table/chart/diagram) in 150 words.</p>
                      </div>
                      <div className="content-sections">
                        <div>Task 2</div>
                        <p>Write an essay (250 words) in response to a point of view, argument or problem.</p>
                      </div>
                    </div>
                    <p className="content-disclaimer" style={{color: '#000'}}><strong>Scoring scale:</strong> <span style={{fontSize: '15px'}}>0-9 brands</span></p>
                    <div className="d-flex align-items-center">
                       <LaunchTestButton testId = {props.testId} courseId = {props.courseId} classList="trymock-btn btn" text="TRY MOCK TEST" />
                      <div className="free-label">FREE!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
)

export default WritingModule
